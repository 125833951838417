<template>
  <div class="resetPassword">
    <div class="centerContent">
      <section class="formContainer">
        <div v-if="resetSuccess === -1">
          <div class="formTop twocol">
            <h2>Reset Password</h2>
          </div>
          <div class="formBody">
            <p>
              Please enter and confirm your new password below, then click
              submit button.
            </p>
            <!--  -->
            <form>
              <!-- password -->
              <div class="field position-r">
                <!-- password validation hint -->
                <label class="lineLable">New Password*</label>
                <div
                  @click="togglePassword"
                  v-show="pswd.length > 0 || pswd2.length > 0"
                  class="icon fixRight iconBtn"
                >
                  <font-awesome-icon
                    :icon="pswdVisible ? 'eye-slash' : 'eye'"
                  />
                </div>
                <!-- first password -->
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd"
                    @input="passwordCheck"
                    required
                    class="fullWidth"
                  />
                  <span class="icon fixRight validated" v-show="pswdValidated"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
              </div>
              <div class="field">
                <!-- second password -->
                <label class="lineLable">Repeat Password*</label>
                <div class="iconInput">
                  <input
                    :type="pswdType"
                    v-model="pswd2"
                    required
                    class="fullWidth"
                  />
                  <p class="formErrorMes" v-show="pswd !== pswd2">
                    Password and Repeat password do not match
                  </p>
                  <span
                    class="icon fixRight validated"
                    v-show="pswdValidated && pswd === pswd2"
                    ><font-awesome-icon icon="check"
                  /></span>
                </div>
              </div>
              <div
                v-show="!pswdValidated && (pswd.length > 0 || pswd2.length > 0)"
                class="hintBox"
              >
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': pswd.length >= 8 }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="pswd.length >= 8"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!(pswd.length >= 8)"
                  />
                  Longer than 8 characters
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_uppercase }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_uppercase"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_uppercase"
                  />
                  Has a capital letter
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_number }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_number"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_number"
                  />
                  Has a number
                </p>
                <p
                  class="frmValidation"
                  :class="{ 'frmValidation--passed': has_special }"
                >
                  <font-awesome-icon
                    class="frmIcon"
                    icon="check"
                    v-show="has_special"
                  />
                  <font-awesome-icon
                    class="frmIcon"
                    icon="times"
                    v-show="!has_special"
                  />
                  Has a special character
                </p>
              </div>
              <div>
                <button
                  class="button button--blue"
                  :disabled="!formValidated"
                  @click.prevent="updatePassword"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- form feedback success -->
        <div class="feedback" :class="{ expand: resetSuccess === 1 }">
          <h2>Congratulations!</h2>
          <p>
            Your password reset was successful and you can now
            <router-link :to="{ name: 'login' }" class="blueTextLink"
              >login</router-link
            >
          </p>
        </div>
        <!-- form feedback fail -->
        <div class="feedback" :class="{ expand: resetSuccess === 0 }">
          <h2>Oops!</h2>
          <p class="field">
            Something went wrong. <br />
            Please try to
            <router-link :to="{ name: 'forgot-password' }" class="blueTextLink"
              >reset your password</router-link
            >
            again or<br />contact
            <a href="mailto:support@hotspexstudio.com" class="blueTextLink"
              >support@hotspexstudio.com</a
            >
            for assistance.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import * as wsUtils from "@/utils/wsUtils.js";
// ?eid=mouseswimming@gmail.com&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6Im1vdXNlc3dpbW1pbmdAZ21haWwuY29tIiwibmJmIjoxNjM0MTM1NDIxLCJleHAiOjE2MzQxMzYwMjEsImlhdCI6MTYzNDEzNTQyMX0.h0PMIrYhhvYwFB82uifaNvacVZAjYdMpyNarXnzxK9c
// Hotspex-123
export default {
  data() {
    return {
      pswd: "",
      pswd2: "",
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      feedback: "",
      pswdVisible: false,
      pswdValidated: false,
      email: "",
      token: "",
      resetSuccess: -1,
    };
  },
  computed: {
    pswdType() {
      return this.pswdVisible ? "text" : "password";
    },
    formValidated() {
      return this.pswdValidated && this.pswd === this.pswd2;
    },
  },
  methods: {
    passwordCheck() {
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      this.has_number = /\d/.test(this.pswd);
      this.has_uppercase = /[A-Z]/.test(this.pswd);
      this.has_special = format.test(this.pswd);

      if (
        this.pswd.length >= 8 &&
        this.has_number &&
        this.has_uppercase &&
        this.has_special
      ) {
        this.pswdValidated = true;
      } else {
        this.pswdValidated = false;
      }
    },
    updatePassword() {
      const pswdPromise = new Promise((resolve) => {
        wsUtils.UpdatePassword(resolve, {
          Email: this.email,
          Password: this.pswd,
          token: this.token,
        });
      });

      pswdPromise.then((data) => {
        if (data.Success) {
          // password updated;
          this.feedback = "";
          this.resetSuccess = 1;
        } else {
          // update failed.
          this.resetSuccess = 0;
        }
      });
    },
    togglePassword() {
      this.pswdVisible = !this.pswdVisible;
    },
  },
  created() {
    const params = this.$route.query;
    this.email = params.eid;
    this.token = params.token;
  },
};
</script>

<style lang="scss" scoped>
.resetPassword {
  background: url("../../public/images/logBG4.jpg") center no-repeat;
  background-color: #000;
  background-size: cover;
  height: 100%;
  background-attachment: fixed;

  display: grid;
}

form {
  margin: 2em 0;
}

.failBG {
  margin-bottom: 1em;
  padding: 1em;
}

.field {
  .iconInput {
    position: relative;
  }

  .icon {
    background-color: var(--iconBGGrey);
    color: #fff;
    height: 32px;
    width: 30px;
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 4px 0 0 4px;
    margin-right: -4px;
  }

  .icon.fixRight {
    background-color: transparent;
    color: var(--iconBGGrey);
  }

  .iconBtn {
    cursor: pointer;
  }

  .icon + input {
    transition: background-color 0.5s;
  }

  .icon + input:focus {
    background-color: var(--lightBlue);
  }
}

.hintBox {
  background: #f6f6f6;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 4px;
  margin: 30px 0 20px;
  h3 {
    font-size: 13px;
  }
}

.validated {
  color: var(--succeedGreen) !important;
}

.frmValidation {
  font-size: 13px;
  margin-bottom: 5px;
  padding-left: 18px;
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
}
.frmValidation--passed {
  color: #717b85;
}

.frmIcon {
  color: var(--hsred);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.frmValidation--passed .frmIcon {
  color: var(--succeedGreen);
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  padding: 0 20px;
  text-align: center;
  max-height: 0;
  line-height: 1.8em;
  // font-size: 1.2em;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
  padding: 20px;
}

.feedback {
  transition: all 0.6s;
  opacity: 0;
  transition-timing-function: ease-in-out;
  padding: 0;
  text-align: center;
  max-height: 0;
}

.feedback.expand {
  max-height: 90vh;
  opacity: 1;
  padding: 20px;
}
</style>
